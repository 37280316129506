@import 'src/styles/variables';

$fontColor: $black80;

.layoutContainer {
  background: $black6 !important;
}

.hexagons {
  g {
    opacity: 1;
  }
}

.layoutContent * {
  color: $fontColor;
}

// HEADER
.header {
  color: $fontColor;
}

.logo {
  svg {
    path {
      fill: $white;
    }
  }
}

.lovePhrase {
  color: $fontColor !important;
}

// FORM NAVIGATION
.formNavigation * {
  color: $fontColor !important;
}

// FORM CONTAINER
.formContainer {
  background-color: $white;
  border: 0;
}

// INPUT
.inputLabel {
  color: $fontColor;
}

.input {
  background: $black6 !important;
}

.button {
  background-color: $blue500 !important;
  color: $white !important;
}

.invertedButton {
  background-color: #e0eaff !important;
  color: $blue500 !important;
}

// COMMON QUESTIONS
.commonQuestions {
  .questions {
    background-color: $white;
    border: 0;
  }

  .selectArrow {
    path {
      stroke: $black100;
    }
  }
}

// FOOTER
.needHelp * {
  color: $blue500 !important;
}

.chatIcon {
  path {
    fill: $blue500;
  }

  circle {
    fill: $white;
  }
}

.copyright {
  color: $fontColor;
}
