@import 'src/styles/variables';

$fontColor: $white;

.text {
  color: $fontColor !important;
}

.layoutContainer {
  background: linear-gradient(180deg, #2046c8 1.31%, #2f7aef 100%) !important;
}

.layoutContent * {
  color: $fontColor;
}

// HEADER
.header {
  color: $fontColor;
}

.logo {
  path {
    fill: $white;
  }
}

.lovePhrase {
  color: $fontColor !important;
}

// FORM NAVIGATION
.formNavigation * {
  color: $fontColor !important;
}

.backLink {
  path {
    fill: $white;
  }

  &:hover {
    color: $fontColor;

    svg path {
      fill: $white !important;
    }
  }
}

// FORM CONTAINER
.formContainer {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border: 0;

  .subtitle {
    color: $fontColor !important;
  }
}

// INPUT
.inputLabel {
  color: $fontColor !important;
}

.input {
  background-color: $blue300 !important;
  border-color: transparent !important;
}

.input::placeholder {
  color: $black10 !important;
}

.button {
  background-color: $white !important;
  color: $blue500 !important;
}

// COMMON QUESTIONS
.commonQuestions {
  .questions {
    background-color: $blue500;
    border: 0;
  }

  .selectArrow {
    path {
      stroke: $white;
    }
  }
}

// FOOTER
.needHelp * {
  color: $fontColor !important;
}

.chatIcon {
  path {
    fill: $white;
  }

  circle {
    fill: $blue500;
  }
}

.copyright {
  color: $fontColor;
}
